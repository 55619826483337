import React from 'react';
import * as Chakra from '@chakra-ui/react';
import {PressLessImage} from "./gatsby-image-svg-fallback";

export interface VideoImageProps extends Chakra.BoxProps {
    video_url: string
    image: any
    wrapperProps?: { [key: string]: any }
    videoProps?: { [key: string]: any }
}

export const ResponsiveVideoImage = ({video_url, image, objectFit = "cover", objectPosition = "center center", wrapperProps, videoProps, ...props}: VideoImageProps) => {

    const hasImage = (() => {

        if (image && typeof image === "object" && "src" in image) {
            if (!image.src) {
                return false
            }
        } else {
            return !!image
        }

    })()


    if (!video_url) {
        return (
            <PressLessImage objectFit={objectFit} objectPosition={objectPosition} image={image} {...props} />
        )
    }

    return (
        <>
            <Chakra.Box
                width="100%"
                height="100%"
                position="relative"
                {...wrapperProps}
            >
                {hasImage && <PressLessImage width="100%" top="0" height="100%" objectFit={objectFit} image={image} {...props} {...videoProps} />}
                {video_url && <Chakra.Box
                    height="100%"
                    backgroundRepeat="no-repeat"
                    position={hasImage ? "absolute" : "relative"}
                    as="video"
                    top="0"
                    backgroundSize="cover"
                    backgroundPosition="center center"
                    objectFit={objectFit}
                    objectPosition={objectPosition}
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    playsInline
                    autoPlay
                    loop
                    muted
                    width="100%"
                    {...props}
                    {...videoProps}
                >
                    <source src={video_url} type="video/mp4"/>
                    <source src={video_url} type="video/ogg"/>
                    Your browser does not support the video tag.
                </Chakra.Box>}
            </Chakra.Box>
        </>

    )


}
