import { GlobalState } from "react-gstate"
import React from "react"
import {
  motion,
  useSpring,
  useTransform,
  useViewportScroll,
} from "framer-motion"
import { useWindowSize } from "@react-hook/window-size"
import * as Chakra from "@chakra-ui/react"
import useScrollPosition from "@react-hook/window-scroll"
import { useInView } from "react-intersection-observer"

export interface AnimationState {
  pageHeight: number
  viewportWidth: number
  viewportHeight: number
  renderedOnce: boolean
  isChanging: boolean
}

class AnimationStateStore extends GlobalState<AnimationState> {
  getViewportWidth = () => {
    if (typeof window !== "undefined") {
      return Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
      )
    }
    return 0
  }

  getViewportHeight = () => {
    if (typeof window !== "undefined") {
      return Math.max(
        document.documentElement.clientHeight || 0,
        window.innerHeight || 0
      )
    }
    return 0
  }

  getPageHeight = () => {
    if (typeof window !== "undefined") {
      return document.body.clientHeight
    }
    return 0
  }

  currentPageAsScrollRef = { current: 0 }
  isChangingRef = { current: false }
  isDesktopAsStateRef = { current: true }

  setIsChangingRef = (data) => {
    this.isChangingRef.current = data
    this.setState({ isChanging: data })
  }

  syntheticScrollTo = (offset, callback) => {
    const fixedOffset = offset.toFixed()
    const onScroll = function () {
      if (window.pageYOffset.toFixed() === fixedOffset) {
        window.removeEventListener("scroll", onScroll)
        callback()
      }
    }

    window.addEventListener("scroll", onScroll)
    onScroll()
    window.scrollTo({
      top: offset,
      behavior: "smooth",
    })
  }

  initialRun = () => {
    if (typeof window !== "undefined") {
      window.onresize = this.doResize
      this.setState({
        renderedOnce: true,
        viewportWidth: this.getViewportWidth(),
        viewportHeight: this.getViewportHeight(),
        pageHeight: this.getPageHeight(),
      })
    }
  }

  doResize = () => {
    this.initialRun()
  }

  getPositionOfElement = (ref) => {
    if (ref.current) {
      const {
        top,
        right,
        bottom,
        left,
        width,
        height,
        x,
        y,
      } = ref.current.getBoundingClientRect()

      const pageTop = window.pageYOffset + top
      const pageBottom = pageTop + height
      return {
        top,
        right,
        bottom,
        left,
        width,
        height,
        x,
        y,
        pageTop,
        pageBottom,
      }
    }
  }
}

export const AnimationState = new AnimationStateStore({
  viewportWidth: 1920,
  viewportHeight: 1080,
  pageHeight: 1080,
  renderedOnce: false,
  isChanging: false,
})

export const useScrollTrigger = (depth = 1) => {
  const containerRef = React.useRef()
  const childRef = React.useRef()

  const { scrollY } = useViewportScroll()

  const [pageTop, setPageTop] = React.useState(0)
  const [pageBottom, setPageBottom] = React.useState(0)
  const [childWidth, setChildWidth] = React.useState(0)
  const [childHeight, setChildHeight] = React.useState(0)
  const [positionStyle, setPositionStyle] = React.useState("relative")

  useAnimatableLayoutEffect(() => {
    if (containerRef?.current && childRef?.current) {
      const childPos = AnimationState.getPositionOfElement(childRef)
      const containerPos = AnimationState.getPositionOfElement(containerRef)

      if (containerPos.height === 0) {
        containerPos.pageBottom = containerPos.pageTop + childPos.height * depth
        containerPos.height = childPos.height * depth
      }

      let pageTopCalc =
        containerPos.pageTop -
        childPos.height * depth +
        AnimationState.getViewportHeight() * 0.5
      let pageBottomCalc =
        containerPos.pageBottom -
        AnimationState.getViewportHeight() +
        childPos.height * depth

      if (childPos.height > AnimationState.getViewportHeight()) {
        const vhP = AnimationState.getViewportHeight() * 0.3
        pageTopCalc = containerPos.pageTop - vhP
        pageBottomCalc = containerPos.pageTop - vhP + childPos.height
      }

      setPageTop(pageTopCalc)
      setPageBottom(pageBottomCalc)

      setChildWidth(childPos.width)
      setChildHeight(childPos.height)

      const pS =
        childPos.height < AnimationState.getViewportHeight()
          ? "sticky"
          : "relative"
      setPositionStyle(pS)
    }
  }, [])

  const transform = useTransform(scrollY, [pageTop, pageBottom], [0, 100])
  const physics = { damping: 15, mass: 0.27, stiffness: 55 }
  const progressSpring = useSpring(transform, physics)
  const progress = useTransform(progressSpring, [0, 100], ["0%", "-100%"])

  const viewportHeight = AnimationState.getViewportHeight()

  return {
    containerRef,
    childRef,
    progress,
    rawProgress: progressSpring,
    childWidth,
    childHeight,
    containerStyles: {
      style: {
        height:
          childHeight < AnimationState.getViewportHeight()
            ? `${childHeight * depth}px`
            : "auto",
      },
    },
    childStyles: {
      style: {
        position: positionStyle,
        top:
          childHeight > viewportHeight
            ? "30%"
            : `${viewportHeight / 2 - childHeight / 2}px`,
      },
    },
  }
}

interface ParallaxProps extends Chakra.BoxProps {
  x?: number[]
  y?: number[]
}

export const Parallax = ({
  x = [0, 0],
  y = [0, 0],
  children,
  ...props
}: ParallaxProps) => {
  const containerRef = React.useRef()

  const [pageTop, setPageTop] = React.useState(0)
  const [pageBottom, setPageBottom] = React.useState(0)

  const doCalc = () => {
    if (containerRef?.current) {
      const containerPos = AnimationState.getPositionOfElement(containerRef)
      setPageTop(containerPos.pageTop)
      setPageBottom(containerPos.pageBottom)
    }
  }

  const { scrollY } = useViewportScroll()

  const transform = useTransform(
    scrollY,
    [Math.max(pageTop - AnimationState.getViewportHeight(), 0), pageBottom],
    [y[0], y[1]]
  )

  const transformX = useTransform(
    scrollY,
    [Math.max(pageTop - AnimationState.getViewportHeight(), 0), pageBottom],
    [x[0], x[1]]
  )

  const physics = { damping: 30, mass: 0.05, stiffness: 200 } // easing of smooth scroll
  const progress = useSSRSpring(transform, physics, y[0])

  const progressX = useSSRSpring(transformX, physics, x[0])

  useAnimatableLayoutEffect(() => {
    doCalc()
  }, [containerRef, pageTop, pageBottom])

  return (
    <Chakra.Box ref={containerRef} {...props}>
      <Chakra.Box
        as={motion.div}
        style={{ x: progressX, y: progress }}
        css={{
          transform: `translateY(${y[0]}px) translateX(${x[0]}px)`,
          "@media (max-width: 62em)": {
            transform: "none!important",
            opacity: "1!important",
          },
        }}
        opacity={{ base: "" }}
      >
        {children}
      </Chakra.Box>
    </Chakra.Box>
  )
}

export const useAnimatableLayoutEffect = (effect, deps = []) => {
  const [windowWidth, windowHeight] = useWindowSize()

  React.useLayoutEffect(() => {
    if (typeof window !== "undefined") {
      effect()

      // It's crazy that setInterval is cheaper than Intersection Observer. Oh well.
      const timer = setInterval(() => {
        effect()
      }, 1000)

      return () => {
        clearInterval(timer)
      }
    }
  }, [...deps, windowWidth, windowHeight])
}

const useSSRSpring = (transform, physics, initialValue) => {
  const [hasRenderedOnce, setHasRenderedOnce] = React.useState(false)
  const progress = useSpring(transform, physics)

  React.useEffect(() => {
    setTimeout(() => {
      setHasRenderedOnce(true)
    }, 500)
  }, [])

  if (!hasRenderedOnce) {
    // @ts-ignore
    progress.current = progress.prev = initialValue
    progress.set(initialValue)
  }

  return progress
}

export const ScrollFadeIn = ({ initialInView = false, ...props }) => {
  const [isSsr, setIsSsr] = React.useState(true)

  const { ref, inView } = useInView({
    rootMargin: "128px",
    threshold: 0.2,
    initialInView: isSsr ? true : initialInView,
  })

  React.useEffect(() => {
    setIsSsr(false)
  }, [])

  const previousScrollPosition = React.useRef<number>(0)
  const scrollY = useScrollPosition(5)
  const scrollDirection = previousScrollPosition.current > scrollY ? "up" : "down"
  previousScrollPosition.current = scrollY

  return (
    <Chakra.Box width="100%" overflow="hidden">
      <Chakra.Box
        ref={ref}
        width="100%"
        transition="opacity 0.7s, transform 0.4s"
        style={{
          transform: inView
            ? "translateY(0px)"
            : "translateY(0px)",
          opacity: inView ? 1 : 0,
        }}
        {...props}
      />
    </Chakra.Box>
  )
}
