import React from "react"
import * as Chakra from "@chakra-ui/react"
import { useInView } from "react-intersection-observer"
import {ResponsiveVideoImage} from "./video-image";

interface HeroImageProps {
  projectName?: string
  projectDescription?: string
  video_url?: string
  image?: any
  imagePosition?: "top" | "center" | "bottom"
  unsetHeightOnMobile?: "no" | "yes"
}

const HeroImage = React.forwardRef((props: HeroImageProps, ref) => {

  const { ref: inViewRef, inView } = useInView({
    threshold: 0.01,
    initialInView: true,
  })

  return (
    <Chakra.Box
      width="100%"
      display="flex"
      position="relative"
      alignItems="flex-start"
      flexDirection="column"
      backgroundSize="cover"
      backgroundColor="transparent"
      backgroundRepeat="no-repeat"
      backgroundPosition="center"
      marginTop="-123px"
      css={{
        "@media (max-width: 991px)": {
          marginTop: "-75px"
        }
      }}
      ref={ref}
    >
      <style>
        {inView && `:root { --hero-text-col: #fff; } .primary-header { background-color: transparent; } .primary-header-border { background-color: transparent; }`}
      </style>
      <Chakra.Box width="100%" ref={inViewRef}>
      <ResponsiveVideoImage
          image={props.image}
          video_url={props.video_url}
          width="100%"
          height="100vh"
          objectFit="cover"
          objectPosition={props.imagePosition ? props.imagePosition : "center"}
          css={{
            "@media (max-width: 991px)": {
              height: props.unsetHeightOnMobile === "yes" ? "unset" : "100vh"
            }
          }}
      />
      </Chakra.Box>
    </Chakra.Box>
  )
})

HeroImage.pressless = {
  name: "Hero Image",
  attributes: {
    image: {
      name: "Background Image",
      type: "Image",
    },
    video_url: {
      name: "Video (URL)",
      type: "PlainText",
    },
    imagePosition: {
      name: "Image Position",
      type: "Select",
      options: [
          "center",
          "top",
          "bottom"
      ]
    },
    unsetHeightOnMobile: {
      name: "Unset Height on Mobile",
      type: "Select",
      description: "This will be handy for videos that should retain their aspect ratio on mobile.",
      options: [{ no: "No" }, { yes: "Yes" }],
    },
  },
}

export default HeroImage
