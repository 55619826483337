import React from "react"
import * as Chakra from "@chakra-ui/react"
import {ResponsiveVideoImage} from "../video-image";

interface FullWidthImageProps {
  image: any
  video_url?: string
  removeMaxHeight: "yes" | "no"
  fullScreenWidth: "yes" | "no"
}

const FullWidthImage = React.forwardRef((props: FullWidthImageProps, ref) => {
  return (
    <Chakra.Box
      width="100%"
      display="flex"
      position="relative"
      maxWidth={props?.fullScreenWidth === "yes" ? "100%" : "1800px"}
      paddingLeft={props?.fullScreenWidth === "yes" ? "0" : "40px"}
      paddingRight={props?.fullScreenWidth === "yes" ? "0" : "40px"}
      marginLeft="auto"
      marginRight="auto"
      ref={ref}
      css={{
        "@media (max-width: 767px)": {
          paddingLeft: "20px",
          paddingRight: "20px",
        },
      }}
      minHeight="200px"
      className="project-block"
    >
      <ResponsiveVideoImage
        image={props.image}
        video_url={props.video_url}
        width="100%"
        height="100%"
        maxHeight={props.removeMaxHeight === "yes" ? "unset" : "844px"}
        objectFit="cover"
      />
    </Chakra.Box>
  )
})

FullWidthImage.pressless = {
  name: "Full Width Image",
  attributes: {
    image: {
      name: "Image",
      type: "Image",
    },
    video_url: {
      name: "Video (URL)",
      type: "PlainText",
    },
    removeMaxHeight: {
      name: "Remove Max Height",
      type: "Select",
      options: [{ no: "No" }, { yes: "Yes" }],
      description: "Remove the preset Maximum Height defined in the design. This will allow for correct aspect ratio of images."
    },
    fullScreenWidth: {
      name: "Stretch to 100% screen width",
      type: "Select",
      options: [{ no: "No" }, { yes: "Yes" }],
      description: "Removes all gutters and Maximum Width from the block. Will stretch the image to any screen size. If using in conjunction with Remove Max Height, use with caution (with respect to very large/wide screen sizes)."
    },
  },
}

export default FullWidthImage
