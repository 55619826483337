import React from "react";
import * as Chakra from "@chakra-ui/react";
import { PressLessMessageBox } from "../../utils/dnep";
import {Helmet} from "react-helmet";

interface ModeSwitchProps{
  modeSelector: string;
}


const ModeSwitch: PressLessComponent<ModeSwitchProps> = React.forwardRef<HTMLDivElement, ModeSwitchProps>(({modeSelector}: ModeSwitchProps, ref) => {


  return (
      <>
        <PressLessMessageBox>
          <Chakra.Box ref={ref}>
            This component helps you switch to either light or dark mode
          </Chakra.Box>
        </PressLessMessageBox>
        <Helmet>
          {modeSelector === "dark" ? <style>{`  :root { --hero-text-col: #fff; --background-col: #000; --text-col: #fff; } `}</style> : <style>{`  :root { --hero-text-col: #000; --background-col: #F4F2ED; --text-col: #000; } `}</style>}
        </Helmet>
      </>

  )
});

ModeSwitch.pressless = {
  name: "Mode Switch",
  attributes: {
    modeSelector: {
      name: "Mode Selector",
      type: "Select",
      options: [{ light: "Light Mode" }, { dark: "Dark Mode" }],
    },
  },
}


export default ModeSwitch;
